import React, { useContext } from 'react';
import { RainContext } from '../../App';
import CloudDrizzle from '../CloudIcon/CloudDrizzle';
import CloudLightning from '../CloudIcon/CloudLightning';
import CloudOff from '../CloudIcon/CloudOff';
import './style.scss';

const ToggleStorm = () => {
  const context = useContext(RainContext);
  const { storming, isRainDisabled, setStorming, disableRain } = context;

  const rainToggleText = isRainDisabled ? 'Make it rain' : 'Turn off the rain';
  const rainToggleClass = isRainDisabled ? 'off' : 'on';

  return (
    <div className="toggle-storm">
      {!isRainDisabled && (
        <button
          className="toggle-button toggle-storm-cloud"
          title="toggle the storm"
          onClick={() => setStorming(!storming)}
        >
          {storming ? <CloudDrizzle /> : <CloudLightning />}
        </button>
      )}
      <button
        className={`toggle-button rain-toggle rain-toggle--${rainToggleClass}`}
        title={rainToggleText}
        onClick={() => disableRain(!isRainDisabled)}
      >
        {isRainDisabled ? <CloudDrizzle /> : <CloudOff />}
      </button>
    </div>
  );
};

export default ToggleStorm;
