import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import './SkullLogo.scss';

const SkullLogo = () => (
  <div className="logo-container">
    <Parallax scale={[-0.5, 1, 'easeInQuad']}>
      <div>
        <img
          src="https://i.ibb.co/PN2n3LD/spotify-logo-2023-transparent-web.png"
          alt="spookerino skull logo"
        />
      </div>
    </Parallax>
  </div>
);

export default SkullLogo;
