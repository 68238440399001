import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

/**
 * This uses up way too much CPU and the chrome GPU renderer as well
 * Even after removing the rain it still makes my computer scream
 */
const RainContainerStorm = () => {
  const rainContainer = useRef(null);
  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(60,window.innerWidth / window.innerHeight, 1, 1000);
    camera.position.z = 1;
    camera.rotation.x = 1.16;
    camera.rotation.y = -0.12;
    camera.rotation.z = 0.27;

    const ambient = new THREE.AmbientLight(0x555555);
    scene.add(ambient);
    const directionalLight = new THREE.DirectionalLight(0xffeedd);
    directionalLight.position.set(0,0,1);
    scene.add(directionalLight);

    const renderer = new THREE.WebGLRenderer();
    scene.fog = new THREE.FogExp2(0x11111f, 0.002);
    renderer.setClearColor(scene.fog.color);
    renderer.setSize(window.innerWidth, window.innerHeight);

    // append the stormy rain canvas
    rainContainer.current.appendChild(renderer.domElement);

    // rain
    const rainCount = 5000;
    const rainGeo = new THREE.Geometry();
    for (let i=0;i<rainCount;i++) {
      const rainDrop = new THREE.Vector3(
        Math.random() * 400 -200,
        Math.random() * 500 - 250,
        Math.random() * 400 - 200
      );
      rainDrop.velocity = {};
      rainDrop.velocity = 0;
      rainGeo.vertices.push(rainDrop);
    }
    const rainMaterial = new THREE.PointsMaterial({
      color: 0xaaaaaa,
      size: 0.1,
      transparent: true
    });
    const rain = new THREE.Points(rainGeo,rainMaterial);
    scene.add(rain);

    // smoke 1
    // https://i.ibb.co/S624d3n/smoke-1.png
    const loader = new THREE.TextureLoader();
    const cloudParticles = [];
    loader.load('https://i.ibb.co/S624d3n/smoke-1.png', (texture) => {
      const cloudGeo = new THREE.PlaneBufferGeometry(500,500);
      const cloudMaterial = new THREE.MeshLambertMaterial({
        map: texture,
        transparent: true
      });
      for (let p=0; p<25; p++) {
        const cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
        cloud.position.set(
          Math.random()*800 -400,
          500,
          Math.random()*500 - 450
        );
        cloud.rotation.x = 1.16;
        cloud.rotation.y = -0.12;
        cloud.rotation.z = Math.random()*360;
        cloud.material.opacity = 0.6;
        cloudParticles.push(cloud);
        scene.add(cloud);
      }
      animate();
    });

    // lightning flashes
    const flash = new THREE.PointLight(0x062d89, 30, 500 ,1.7);
    flash.position.set(200,300,100);
    scene.add(flash);

    const animate = () => {
      cloudParticles.forEach(p => {
        p.rotation.z -=0.002;
      });

      rainGeo.vertices.forEach(p => {
        p.velocity -= 0.1 + Math.random() * 0.1;
        p.y += p.velocity;
        if (p.y < -200) {
          p.y = 200;
          p.velocity = 0;
        }
      });
      rainGeo.verticesNeedUpdate = true;
      rain.rotation.y +=0.002;
      if (Math.random() > 0.93 || flash.power > 100) {
        if (flash.power < 100) {
          flash.position.set(
            Math.random()*400,
            300 + Math.random() *200,
            100
          );
        }
        flash.power = 50 + Math.random() * 500;
      }
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };
  }, []);

  return <div className="rain-container rain-container-storm" ref={rainContainer} />;
};

export default RainContainerStorm;
