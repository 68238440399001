import React from 'react';
import PropTypes from 'prop-types';

const RainDrop = ({ top, left, maxHeight }) => (
  <line
    className="rain-drop"
    x1={left}
    x2={left}
    y1={top}
    y2={maxHeight}
    stroke="currentColor"
    strokeWidth="1"
  />
);

RainDrop.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number
};

RainDrop.defaultProps = {
  top: 0,
  left: 400,
  maxHeight: 800
};


export default RainDrop;
