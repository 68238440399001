import React from 'react';
import './FeedList.scss';

const FeedList = () => (
  <div className="feed-list-container">
    <ul className="feed-list">
      <li className="feed-list__item">
        <div>
          <h3>Spooktober playlist 2023</h3>
          <p>Spooky songs to listen to for halloween</p>
          <iframe
            style={{ borderRadius: '12px', border: 'none' }}
            src="https://open.spotify.com/embed/playlist/0NhaJJSqtsqTaUNg13MYkL?utm_source=generator&theme=0"
            width="100%"
            height="352"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>
      </li>
      <li className="feed-list__item">
        <div>
          <h3>Spookerino mix</h3>
          <iframe
            style={{ borderRadius: '12px', border: 'none' }}
            src="https://open.spotify.com/embed/playlist/3Qxc4vdZ31ScmKGdG0CehB?utm_source=generator"
            width="100%"
            height="352"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          />
        </div>
      </li>
      <li className="feed-list__item">
        <div>
          <h3>23 and spook me EP</h3>
          <iframe
            style={{ borderRadius: '12px', border: 'none' }}
            src="https://open.spotify.com/embed/album/0R3U6vqWQXLrrbkXh543Ly?utm_source=generator"
            width="100%"
            height="352"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          />
        </div>
      </li>
    </ul>
  </div>
);

export default FeedList;
