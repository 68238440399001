import React from 'react';
// SVG Skull
// Basically converted: https://codepen.io/viciouskitten/pen/mJEmRZ to SVG
// and oversimplified it a bit
const Skull = () => (
  <svg className="skull" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387.92 481">
    <circle className="left-eye eye" cx="137.75" cy="252.5" r="29.5"/>
    <circle className="right-eye eye" cx="266.75" cy="252.5" r="29.5"/>
    <rect className="left-eye-brow" x="133.25" y="73.33" width="8" height="149.67"/>
    <rect className="forehead_crack" x="188.58" y="73.33" width="10" height="80.67"/>
    <rect className="left_nostril" x="188.58" y="291.33" width="10" height="46.67"/>
    <rect className="right_nostril" x="203.92" y="288.67" width="10.67" height="42"/>
    <rect className="right_head_crack" x="313.92" y="93.5" width="4.67" height="318"/>
    <rect className="bg_top_right" x="318.58" y="93.5" width="42" height="35.83"/>
    <rect className="left_tooth_right_side" x="153.25" y="376" width="6.67" height="37"/>
    <rect className="left_tooth_left_side" x="119.92" y="376" width="4.67" height="37"/>
    <rect className="left_tooth_bottom" x="124" y="401" width="30" height="11" />
    <rect className="left_mid_tooth_right_side" x="198.58" y="378" width="7" height="35" />
    <rect className="right_tooth_left_side" x="246.58" y="376" width="6.67" height="37" />
    <rect className="right_tooth_right_side" x="291.92" y="376" width="26.67" height="37" />
    <rect className="left_black_bar" x="29.25" width="24" height="476.67" />
    <rect className="left-cheek-block" x="63.25" y="342" width="50" height="71" />
    <rect className="right-cheek-block" x="318.58" y="324" width="29.33" height="89" />
    <rect className="black_bottom" x="51.92" y="411.5" width="296" height="70"/>
    <rect className="top_of_headpiece" x="116.58" width="179.67" height="73.33"/>
    <g className="top_right_mid_head" data-name="top right mid head">
      <rect x="266.75" y="73.33" width="47.17" height="20.17"/>
      <rect x="318.58" width="39.33" height="129.33"/>
    </g>
    <rect className="right_block" x="360.58" width="27.33" height="481"/>
    <rect x="88.25" width="15.67" height="93.5"/>
    <rect x="54.58" width="33.67" height="129.33"/>
    <rect className="right_ear_block" x="347" y="129" width="15" height="30"/>
    <rect x="63.25" y="129.33" width="15" height="215.33"/>
    <g className="left-drop drop" data-name="left drop">
      <rect x="13.5" width="6" height="144.33"/>
      <circle cx="16.5" cy="149" r="7.5"/>
    </g>
    <g className="left_face_drop drop" data-name="left face drop">
      <rect x="55.22" y="129.33" width="2.55" height="61.25"/>
      <circle cx="56.49" cy="192.57" r="3.18"/>
    </g>
    <rect className="midface_crack" x="197" y="153.5" width="1.58" height="139"/>
    <g className="white_drop_1 drop" data-name="white drop 1">
      <rect className="fill-white" x="372.75" y="0.83" width="6" height="144.33"/>
      <circle className="fill-white" cx="375.75" cy="149.83" r="7.5"/>
    </g>
    <g className="white-drop-top-of-face drop">
      <rect className="fill-white" x="164.17" width="2.47" height="42.04"/>
      <ellipse className="fill-white" cx="165.4" cy="43.4" rx="3.08" ry="2.18"/>
    </g>
    <g className="white_drop_left_bottom drop">
      <rect className="fill-white" x="59.1" y="393.75" width="2.47" height="59.34"/>
      <circle className="fill-white" cx="60.33" cy="455.01" r="3.08"/>
    </g>
    <g className="white_drop_right_bottom drop">
      <rect className="fill-white" x="318.58" y="324" width="4.2" height="101.03"/>
      <circle className="fill-white" cx="320.68" cy="428.3" r="5.25"/>
    </g>
    <rect className="white-rect-bottom-left fill-white" x="124.58" y="439" width="4.17" height="42"/>
    <rect className="white-rect-bottom fill-white" x="217.25" y="431" width="6.5" height="50"/>
    <rect className="right-of-face-bar" x="339.58" y="129.33" width="8.33" height="194.67"/>
    <rect className="second-left-black-bar" x="19.5" y="177.33" width="11" height="286.67" />
    <rect y="0.83" width="5.92" height="475.83"/>
    <rect className="fill-white" x="214.58" width="5.92" height="46.75"/>
    <rect className="fill-white" x="220.5" width="3.25" height="73.33"/>
  </svg>
);

export default Skull;
