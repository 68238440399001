import React, { useContext } from 'react';
import Skull from './Skull';
import LinkItems from './LinkItems';
import ToggleStorm from './ToggleStorm';
import SkullLogo from './SkullLogo';
import { RainContext } from '../App';
import FeedList from './FeedList';

const ContentContainer = () => {
  const context = useContext(RainContext);
  const { oldSkullShowing, showOldSkull } = context;

  const skullContainerClasses = [
    'container--inner',
    'skull-container',
    oldSkullShowing ? 'skull-container-old' : '',
  ].join(' ');
  return (
    <section className="content-container">
      <div className={skullContainerClasses}>
        {oldSkullShowing && <Skull />}
        {!oldSkullShowing && <SkullLogo />}
      </div>
      <div className="container--inner title-container padding-top">
        <h1 className="App-header">Spookerino</h1>
        <h3>23 and spook me</h3>
      </div>
      <div className="container--inner nav-container">
        <LinkItems />
      </div>
      <div className="padding-top">
        <ToggleStorm />
      </div>
      <div>
        <button
          type="button"
          className="icon-button"
          onClick={() => showOldSkull(!oldSkullShowing)}
        >
          💀
        </button>
      </div>
      <div className="container--inner">
        <FeedList />
      </div>
    </section>
  );
};

export default ContentContainer;
