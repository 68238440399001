import React, { useContext } from 'react';
import RainDrop from './RainDrop';
import { RainContext } from '../../App';
import RainContainerStorm from './RainContainerStorm';
import useWindowSize from '../../hooks/useWindowSize';
import './styles.scss';

// defaults before first draw
const SVG_HEIGHT = 800;
const SVG_WIDTH = 800;

const randRange = (minNum, maxNum) =>
  (Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum);

/**
 * creates random raindrop offsets for given number of rain drops
 * @returns RainDropOffset object { top: number, left: number }
 */
const createDropsArray = (numberOfDrops, width) => {
  if (!numberOfDrops) return [];
  return [...Array(numberOfDrops).keys()].map(dropKey => ({
    top: randRange(-200, 200),
    left: randRange(0, width),
  }));
}

const RainContainer = () => {
  const context = useContext(RainContext);
  const { storming, isRainDisabled } = context;
  const {
    width = SVG_WIDTH,
    height = SVG_HEIGHT,
  } = useWindowSize();

  if (isRainDisabled) {
    return null;
  }

  if (storming) {
    return <RainContainerStorm />;
  }

  const numberOfDrops = width > 700 ? 100 : 50;
  const drops = createDropsArray(numberOfDrops, width);

  return (
    <div className="rain-container">
      <svg viewBox={`0 0 ${width} ${height}`}>
        {drops.map((drop, i) => (
          <RainDrop
            key={`_${drop.top}_${drop.left}`}
            top={drop.top}
            left={drop.left}
            maxHeight={height}
          />
        ))}
      </svg>
    </div>
  );
};

export default RainContainer;
