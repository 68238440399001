import React, { useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import RainContainer from './components/RainContainer/RainContainer';
import ContentContainer from './components/ContentContainer';

const rainDefaults = {
  storming: false,
  isRainDisabled: false,
  setStorming: () => {},
  disableRain: () => {},
  oldSkullShowing: false,
  showOldSkull: () => {},
};

export const RainContext = React.createContext(rainDefaults);

const App = () => {
  const [storming, setStorming] = useState(false);
  const [isRainDisabled, disableRain] = useState(false);
  const [oldSkullShowing, showOldSkull] = useState(false);
  return (
    <ParallaxProvider>
      <RainContext.Provider
        value={{
          storming,
          isRainDisabled,
          setStorming,
          disableRain,
          oldSkullShowing,
          showOldSkull,
        }}
      >
        <div className="App container">
          <ContentContainer />
          <footer className="site-footer">
            <p className="copyright">&copy; Ian Miller</p>
          </footer>
          <RainContainer />
        </div>
      </RainContext.Provider>
    </ParallaxProvider>
  );
};

export default App;
