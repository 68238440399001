import React from 'react';
import FeatherIcon from 'feather-icons-react';
import SkullIcon from '../SkullIcon';
import AppleMusic from '../icons/AppleMusic';
import './style.scss';

const LinkItems = () => (
  <ul className="link-items">
    <li>
      <a
        href="https://brumpo.myspreadshop.com/spooky?collection=AwBReAKmMp"
        className="store-link"
        title="spooky store"
      >
        <span className="hide">Spooky Merch</span>
        <SkullIcon />
      </a>
    </li>
    <li>
      <a
        href="https://distrokid.com/hyperfollow/spookerino/23-and-spook-me"
        className="new spooker big spookyregular spooker--link"
        title="2023 spooky album - 23 and spooky me"
      >
        <span className="hide">2023 spooky ep: 23 and spooky me</span>
        <span aria-hidden="true">z</span>
      </a>
    </li>
    <li>
      <a
        href="https://open.spotify.com/artist/3ZYKfrwmlxERy7lsHZfPgc?si=d9kj6CeXQcCVJVKE4oEEfQ"
        className="social spotify"
        title="SPOOKERINO SPOTIFY"
      >
        <span className="hide">Spotify</span>
        <i className="fa fa-spotify" aria-hidden="true"></i>
      </a>
    </li>
    <li>
      <a
        href="https://instagram.com/djspookerino"
        className="social instagram"
        title="SPOOKERINO INSTAGRAM"
      >
        <span className="hide">Instagram</span>
        <i className="fa fa-instagram" aria-hidden="true" />
      </a>
    </li>
    <li>
      <a
        href="https://soundcloud.com/spookerino"
        className="social soundcloud"
        title="SPOOKERINO SOUNDCLOUD"
      >
        <span className="hide">Soundcloud</span>
        <i className="fa fa-soundcloud" aria-hidden="true" />
      </a>
    </li>
    <li>
      <a
        href="https://www.youtube.com/channel/UCR7TeKFvDCJK_5ZvVLWosvg"
        className="social youtube"
        title="SPOOKERINO YOUTUBE"
      >
        <span className="hide">YouTube</span>
        <FeatherIcon icon="youtube" size={40} />
      </a>
    </li>
    <li>
      <a
        href="https://music.apple.com/us/artist/spookerino/1483169169"
        className="social apple-music"
        title="SPOOKERINO APPLE MUSIC"
      >
        <span className="hide">Apple Music</span>
        <AppleMusic size={40} />
      </a>
    </li>
  </ul>
);

export default LinkItems;
